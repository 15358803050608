/* You can add global styles to this file, and also import other style files */

body {
  min-height: 100vh;
  padding: 0;
  margin: 0;
  font-family: Roboto,Helvetica Neue,sans-serif;
  font-size: 14px;
  background-color: #fafafa;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}


button,
input,
select,
textarea {
  outline: none;
}

:root {
  --color-black: #333;
  --color-white: #fff;
  --color-grey: #999;
  --color-error: #aa0000;
  --color-success: #009900;

  --color-background-light: #eee;
  --color-border: #ccc;
  --color-border-dark: #999;
  --color-primary: #00abf2;
  --color-primary-light: #c0e4f3;
  --color-primary-dark: #0088c1;
  --color-secondary: #eee;
  --color-secondary-dark: #bbb;

  --form-control-height: 32px;
  --form-border-radius: 5px;
}

button {
  &.av-button {
    height: var(--form-control-height);
    display: flex;
    align-items: center;
    padding: 0 10px;
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: var(--form-border-radius);
    color: white;
    cursor: pointer;
    transition: background-color 300ms ease-in;

    &:hover {
      background-color: var(--color-primary-dark);
      border-color: var(--color-primary-dark);
    }

    &.av-secondary {
      background-color: var(--color-secondary);
      border-color: var(--color-border-dark);
      color: var(--color-black);

      &:hover {
        background-color: var(--color-secondary-dark);
        border-color: var(--color-border-dark);
      }
    }
  }
}

form {
  .form-row {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    position: relative;

    label {
      font-weight: bold;
      margin-right: 4px;
    }

    input {
      flex-grow: 1;
      padding: 2px 8px;
    }

    &__submit {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        margin-left: 10px;
      }
    }
  
    &__error {
      color: var(--color-error);
      position: absolute;
      bottom: -2px;
      width: auto;
      font-size: 12px;
      right: 10px;
    }
  }
  .form-error {
    height: 24px;
    color: var(--color-error);
    font-size: 12px;
  }

  input {
    height: var(--form-control-height);
    border: 1px solid var(--color-border);
    border-radius: var(--form-border-radius);
  }
  textarea {
    border: 1px solid var(--color-border);
    border-radius: var(--form-border-radius);
  }
}

.av-link {
  color: var(--color-primary);
  cursor: pointer;
  transition: color 300ms ease-in;

  &:hover {
    color: var(--color-primary-dark);
  }
}

.av-clickable {
  cursor: pointer;
}

// Material overwrites
.mat-dialog-actions {
  justify-content: flex-end;
}